module.exports = [{
      plugin: require('/builds/profyu/profyu/profyu-static-site/node_modules/gatsby-plugin-glamor/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/profyu/profyu/profyu-static-site/node_modules/gatsby-plugin-twitter/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/profyu/profyu/profyu-static-site/node_modules/gatsby-plugin-nprogress/gatsby-browser'),
      options: {"plugins":[],"color":"#DD0F08"},
    },{
      plugin: require('/builds/profyu/profyu/profyu-static-site/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-139144263-1"},
    },{
      plugin: require('/builds/profyu/profyu/profyu-static-site/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/profyu/profyu/profyu-static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
