// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-codepen-example-js": () => import("/builds/profyu/profyu/profyu-static-site/src/templates/codepen-example.js" /* webpackChunkName: "component---src-templates-codepen-example-js" */),
  "component---src-templates-blog-js": () => import("/builds/profyu/profyu/profyu-static-site/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-course-js": () => import("/builds/profyu/profyu/profyu-static-site/src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-news-js": () => import("/builds/profyu/profyu/profyu-static-site/src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-pages-404-js": () => import("/builds/profyu/profyu/profyu-static-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-all-html-js": () => import("/builds/profyu/profyu/profyu-static-site/src/pages/blog/all.html.js" /* webpackChunkName: "component---src-pages-blog-all-html-js" */),
  "component---src-pages-course-intro-html-js": () => import("/builds/profyu/profyu/profyu-static-site/src/pages/course/intro.html.js" /* webpackChunkName: "component---src-pages-course-intro-html-js" */),
  "component---src-pages-index-js": () => import("/builds/profyu/profyu/profyu-static-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-showcase-html-js": () => import("/builds/profyu/profyu/profyu-static-site/src/pages/showcase.html.js" /* webpackChunkName: "component---src-pages-showcase-html-js" */)
}

exports.data = () => import("/builds/profyu/profyu/profyu-static-site/.cache/data.json")

